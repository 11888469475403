<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <h3 class="card-title">Daftar Audit Trail</h3>
    </div>
    <div class="card-body">
      <button
        type="button"
        class="hidden-button"
        @click="refreshData"
        id="refreshButton"
        >
        <i class="fas fa-sync-alt"></i>
      </button>
      <!--begin::Table-->
      <DxDataGrid
        :ref="dataGridRefKey"
        :data-source="dataSource"
        key-expr="id"
        :show-borders="true"
        :word-wrap-enabled="true"
        :remote-operations="true"
      >
        <DxScrolling
          :use-native="true"
          :scroll-by-content="true"
          :scroll-by-thumb="true"
          show-scrollbar="onHover"
        />
        <DxColumn data-field="created_at" caption="Tanggal" :customize-text="dateIndo" />
        <DxColumn data-field="user.email" caption="Username" :allow-sorting="false" />
        <DxColumn data-field="user.role.name" caption="Role" :allow-sorting="false" />
        <DxColumn data-field="entity" caption="Menu" />
        <DxColumn type="buttons" caption="Aksi" :fixed="true">
          <DxButton
            icon="fa fa-eye"
            hint="Lihat"
            :onClick="toDetail"
          />
        </DxColumn>
        <DxPaging :page-size="10" />
        <DxPager
          :visible="true"
          :show-page-size-selector="showPageSizeSelector"
          :allowed-page-sizes="pageSizes"
        />
      </DxDataGrid>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxButton,
  DxPaging,
  DxPager,
  DxScrolling
} from "devextreme-vue/data-grid";
import "devextreme/dist/css/dx.light.css";
import CustomStore from "devextreme/data/custom_store";
import { isNotEmpty } from "@/core/appUtil/util";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import "jspdf-autotable";
import { mapGetters } from "vuex";
import moment from "moment";

let token = "";
let searchQ = "";

const store = new CustomStore({
  key: "id",
  load: function(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      // "filter",
      "totalSummary",
      "group",
      "groupSummary"
    ].forEach(function(i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    // search by name if exists
    if (searchQ.length > 0) {
      // params += "&nomenklatur=" + searchQ;
      if (params.indexOf("?") > -1) {
        params += "&";
      } else {
        params += "?";
      }

      params += "q=" + searchQ;
    }

    let dataProvider = null;

    return fetch(
      process.env.VUE_APP_URL_LOCAL + `/audit-trail${params}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + token
        }
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.data === null) {
          setTimeout(() => {
            document.querySelector("#refreshButton").click();
          }, 500);
          
        } else {
          dataProvider = data.data;
          dataProvider = dataProvider.map((value) => {
            value['user'] = JSON.parse(value.user);

            return value;
          });

          return {
            data: dataProvider,
            totalCount: data.totalCount,
            summary: data.summary,
            groupCount: data.groupCount
          };
        }
      })
      .catch((e) => {
        console.error(e);
        if (!dataProvider) {
          document.querySelector("#refreshButton").click();
        }

        throw "Data Loading Error";
      });
  }
});
const dataGridRefKey = "audit-data-grid";

export default {
  name: "AuditTrail",
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
    DxPaging,
    DxPager,
    DxScrolling
  },
  data() {
    return {
      dataSource: store,
      dataGridRefKey,
      showPageSizeSelector: true,
      pageSizes: [5, 10, 20],
      loading: false,
      search: "",
    };
  },
  mounted() {
    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;
    })
    .then(() => {
    });
  },
  computed: {
    ...mapGetters({
      hasPermission: "token/hasPermission"
    }),
    dataGrid: function() {
      return this.$refs[dataGridRefKey].instance;
    }
  },
  methods: {
    refreshData() {
      this.dataGrid.refresh();
    },
    exportToExcel() {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("AuditTrail");
      console.log(this.dataGrid);

      exportDataGrid({
        component: this.dataGrid,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "AuditTrail.xlsx"
          );
        });
      });
    },
    dateIndo(e) {
      let date = new Date(e.value)

      return date ? moment(date).format('MMMM DD YYYY, h:mm:ss a') : null;
    },
    toDetail(e) {
      const data = e.row.data;
      this.$router.push({ path: `/audit-trail/detail/${data.id}` });
    }
  }
};

</script>
